/* Reset Style */
html {
  font-size: 100%;
  height: 100%;
  margin: 0;
  overflow-x: hidden;
  padding: 0;
  touch-action: manipulation;
  width: 100%;
}

body {
  color: #333;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 400;
  height: 100%;
  margin: 0;
  width: 100%;
  overflow-x: hidden;
  word-wrap: break-word;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
ul,
span,
li,
img,
button {
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: inherit;
  line-height: 1.5;
}

h1,
h2,
h3 {
  font-family: 'Poppins', sans-serif;
}

p {
  color: #555;
  font-size: 1.05em;
  font-weight: 400;
  line-height: 1.6;
}

h1 {
  font-size: 3.5em;
  line-height: 1;
}

/* core css */
.main-area {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 100vh;
  z-index: 1;
  padding: 0 20px;
  background-size: cover;
  background-position: center center;
  color: #fff;
}

.main-area:after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  opacity: .6;
  background: #000;
}

.error-content {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.block-text,
.block-svg {
  padding: 10px 20px;
}

.block-svg {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
}

.title {
  font-size: 4em;
  color: #fff;
}

.sub-title {
  font-size: 1.5em;
  color: #fff;
  padding-left: 10px;
  margin-bottom: 20px;
}

.desc {
  color: #fff;
  font-size: 1em;
  max-width: 600px;
  padding: 0 0 0 10px;
}

.svg-icon {
  height: 150px;
  width: 150px;
}

/* back to home */
.block-back-home {
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn {
  line-height: 50px;
  height: 50px;
  text-align: center;
  width: 250px;
  cursor: pointer;
}

.btn-back {
  color: #FFF;
  transition: all 0.5s;
  position: relative;
}

.btn-back::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: rgba(255, 255, 255, 0.2);
  transition: all 0.3s;
}

.btn-back:hover::before {
  opacity: 0;
  transform: scale(0.5, 0.5);
}

.btn-back::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0;
  transition: all 0.3s;
  border: 1px solid rgba(255, 255, 255, 0.7);
  transform: scale(1.2, 1.2);
}

.btn-back:hover::after {
  opacity: 1;
  transform: scale(1, 1);
}

.block-link {
  text-decoration: none;
  display: block;
}

.btn-text {
  color: #fff;
  font-size: 1.2em;
}

/* Media queries */
@media screen and (min-width: 550px) {
  .title {
    font-size: 4em;
  }

  .sub-title {
    font-size: 1.5em;
  }

  .desc {
    font-size: 1em
  }

  .svg-icon {
    height: 200px;
    width: 200px;
  }
}

@media screen and (min-width: 650px) {
  .title {
    font-size: 6em;
  }

  .sub-title {
    font-size: 2em;
  }

  .desc {
    font-size: 1.2em
  }

  .svg-icon {
    height: 250px;
    width: 250px;
  }
}

@media screen and (min-width: 950px) {
  .error-content {
    flex-direction: row;
  }

  .block-svg {
    padding-top: 0;
  }
}
